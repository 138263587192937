import $ from 'jquery';
import 'bootstrap';

// SMOOTH SCROLLING //
function smooth_scrolling() {
  // Select all links with hashes
  $('a[href*="#"]')
  // Remove links that don't actually link to anything
      .not('[href="#"]')
      .not('[href="#0"]')
  .not('[href*="#collapse"]')
  .click(function(event) {
    // On-page links
    if (
        location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
        location.hostname == this.hostname
    ) {
      // Figure out element to scroll to
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      // Does a scroll target exist?
      if (target.length) {
        // Only prevent default if animation is actually gonna happen
        event.preventDefault();
        $('html, body').animate({
          scrollTop: target.offset().top - 120
        }, 1000, function() {
          // Callback after animation
          // Must change focus!
          var $target = $(target);
          $target.focus();
          if ($target.is(":focus")) { // Checking if the target was focused
            return false;
          } else {
            $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
            $target.focus(); // Set focus again
          };
        });
      }
    }
  });
}

$(() => {
  
  smooth_scrolling();
  
  $('.pagetop').on('click', (e) => {
    e.preventDefault();
    $('html,body').animate({
      scrollTop: 0,
    }, 700);
  });

  $('header a.yoko').on('click', () => {
    // 表示・非表示切り替え
    $('subnav').toggle();
  });

  $('.parent .nav-link').on('click', () => {
    $('#submenu').slideToggle(); // サブメニューを表示
    return false;
  });

  $('form#otoiawase button.submit').on('click', () => {
    $('#loading').html("<i class='fa fa-spinner fa-fw fa-spin'></i>").fadeIn();

    let name = $('input#name').val();
    let tel = $('input#tel').val();
    let email = $('input#email').val();
    let content = $('textarea#content').val();
    let data = `name=${ name }&tel=${ tel }&email=${ email }&content=${ content }`;

    $.ajax({
      type: 'POST',
      url: '../assets/inc/sendEmail.php',
      data,
      success(msg) {
        // Message was sent
        if (msg === 'OK') {
          $('#message-warning').hide();
          $('#message-success').fadeIn();
          $('.submit').attr('disabled', true);
        }
        // There was an error
        else {
          $('#message-warning').html(msg);
          $('#message-warning').fadeIn();
        }
      },
      complete() {
        $('#loading').empty();
      },

    });
    return false;
  });
});
